<template>
  <div
    v-if="items.length"
    class="action-bar bg-dark text-white p-3"
  >
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center flex-grow-1 me-2">
        You have selected {{ items.length }} item(s)
      </div>

      <button
        @click="$emit('actionBarClearSelection')"
        class="btn btn-sm btn-primary ms-2"
      >Clear selections</button>

      <button
        @click="$emit('actionBarUpdateSelection')"
        v-if="enableStatusUpdate"
        class="btn btn-sm btn-primary ms-2"
      >Change status to...</button>

      <button
        @click="$emit('actionBarCopySelection')"
        class="btn btn-sm btn-primary ms-2"
      >Copy</button>

      <IconButton
        @click="showConfirmModal = true"
        :icon="'trash-alt'"
        class="btn btn-sm btn-danger ms-2"
      >Delete</IconButton>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteSelection"
          @close="showConfirmModal = false"
          v-if="showConfirmModal"
          v-model="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'ActionBar',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    enableStatusUpdate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteSelection() {
      this.$emit('actionBarDeleteSelection');
      this.showConfirmModal = false;
    },
  },
  data() {
    return {
      showConfirmModal: false,
      showChangeStatusModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.action-bar {
  position: fixed;
  z-index: 291;
  left: 1rem;
  bottom : 1rem;
  right: 1rem;
  border-radius: $borderRadius;
}
</style>
